import $ from "jquery";

const fixedAction = $('#js-fixedAction');

$(window).scroll(function () {
  if ($(this).scrollTop() > 100) {
    fixedAction.addClass('-active');
  } else {
    fixedAction.removeClass('-active');
  }
});


