import $ from "jquery";

// ハンバーガーメニュー
const active = '-active';
$('#js-globalNavToggle').on('click', (event) => {
  $(event.currentTarget).toggleClass('-active');
  $('#js-globalNav').slideToggle();
  $('#js-fixedTop').toggleClass('-active');
  return false;
});
